@import "variables";

.btn {
  border-radius: 0;
}

.form-control, .input-group-text {
  border-radius: 0;
}


input.error {
  border-color: red;
}

.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 12px;
}

.field-array-row {
  margin-top: -5px;
  margin-bottom: -5px;
}

.form-row {
  margin-top: -5px;
}

.horizontal-line {
  margin-top: -8px;
}

.item-description {
  margin-left: 8px;
  margin-top: -5px;
}





.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 8px;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: grey;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}
